<template>
  <v-select
    v-model="dgiStatus"
    :items="arOptions"
    :menu-props="{ offsetY: true }"
    hide-details="auto"
    item-text="text"
    item-value="value"
    v-bind="obAttrs"
    @change="onChange"
  />
</template>

<script lang="ts">
import { defaults } from "lodash";
import { Component, Vue } from "vue-property-decorator";
import type { DgiStatusCode } from "@/types/utils";

@Component
export default class InvoiceDgiStatusSelect extends Vue {
  arStatusCodeList: DgiStatusCode[] = [
    "00",
    "01",
    "03",
    "05",
    "06",
    "11",
    "12",
    "30",
    "31",
    "89",
    "96",
    "99",
  ];
  arOptions: any[] = [{ text: this.$t(`all`), value: "all" }];
  dgiStatus: string = "all";

  get obAttrs(): Record<string, any> {
    return defaults(this.$attrs, {
      dense: true,
      outlined: true,
    });
  }

  mounted() {
    this.arOptions = this.arOptions.concat(
      this.arStatusCodeList.map((sCode) => ({
        text: this.$t(`status.dgi.${sCode}`),
        value: sCode,
      }))
    );
  }

  onChange(value: any) {
    this.$emit("on_change_in_dgi_status_select", value);
  }
}
</script>
